import TableHead from "@material-ui/core/TableHead";
import React from "react";
import {withStyles} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableRow from "@material-ui/core/TableRow";


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        "&:hover": {
            color: "black",
            background: "yellow"
        }
    },
}))(TableRow);

const LocationHead = (props) => {

    return(<>
    <TableHead style={{
        borderBottomColor: "tomato",
        borderBottomWidth: "20px",
        hover: "blue",
        fontSize: "0.7rem"
    }}>
        <StyledTableRow hover>
            <StyledTableCell style={{backgroundColor:"white",color:"black"}} className={props.classes.TableCellSticky}>

            </StyledTableCell>
            {
                props.daysOfMonthList.map((day) => {
                    return(
                        <StyledTableCell style={{minWidth: 30, fontWeight: 'bold', textAlign: 'center',backgroundColor:"white",color:"black"}} key={day} className={props.classes.TableCellSticky}>
                            {day}
                        </StyledTableCell>
                    );
                })
            }
        </StyledTableRow>
    </TableHead>
        </>
    );
};

export default LocationHead;
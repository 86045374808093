import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from "@material-ui/lab";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { de, enGB, frCH } from 'date-fns/locale';
import { formatWithOptions } from 'date-fns/fp'
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
    CircularProgress,
    Container,
    makeStyles,
    TableContainer
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import axios from "axios";
import sha1 from"js-sha1"
import ContingentRow from "./components/ContingentRow";
import LocationHead from "./components/LocationHead";


const useStyles = makeStyles({
    mainContainer: {
        marginTop: '15px',
        minWidth:'320px'
    },
    datepicker: {
        maxWidth: '150px'
    },
    datepickerAndButtons: {
        marginTop: '10px',
        marginBottom: '5px',
        webkitColumnBreakInside: 'avoid',
        pageBreakInside: 'avoid',
        breakInside: 'avoid'
    },
    table: {
        backgroundColor: "yellowgreen"
    },
    tableRow: {
        hover: {

            '&$hover:hover': {
                // Set hover color
                backgroundColor: "greenyellow",
            },
        },
    },
    scrollBar: {
        '&::-webkit-scrollbar': {
            width: '0.8em'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 2px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#EB6A3A',
            outline: '2px solid slategrey'
        }
    },
    optionGrid: {
        textAlign: 'center',
        contentAlign: 'center',
        margin: '5px',
    },
    TableCellSticky: {
        position: 'sticky',
        background: '#fff',
        left: 0,
        zIndex: 1,
        minWidth: "20px"
    }
});

const Calendar = (props) => {

    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [locationId, setLocationId] = useState(props.location_id);
    const [locations, setLocations] = useState([]);
    const [availables, setAvailables] = useState([]);
    const [daysOfMonthList, setDaysOfMonthList] = useState([]);
    const [allLocations, setAllLocations] = useState(false);
    const [locationsMap, setLocationsMap] = useState([]);
    const [chosenDate, setChosenDate] = useState(false);
    const minDate = new Date();

    const languageMap = {
        'de_DE': de,
        'en_GB': enGB,
        'fr_FR': frCH
    };
    const language = languageMap[props.language];

    const dateToString = formatWithOptions({ locale: de }, 'D MMMM YYYY');
    useEffect(() => {
        setDaysOfMonthList(Array.from({length: daysInThisMonth()}, (_, i) => i + 1))
        axios.get(props.apiHost + `/locations?find=short&fe_visible=1&hash=` + props.hash , {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': props.language
            }
        }).then((res) => {
            if(typeof props.network !== 'undefined'){
                let tempLocations = res.data.data;
                let newlocations = [];
                for(let l = 0; l < tempLocations.length; l++){
                    let networks = tempLocations[l]['networks'].split(',');
                    for(let n=0; n < networks.length; n++){
                        if(networks[n].trim() === props.network){
                            newlocations[l] = tempLocations[l];
                        }
                    }
                }
                setLocations(newlocations);
            } else {
                setLocations(res.data.data);
            }

        })
    }, [props]);

    useEffect(() => {
        var firstDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
        var lastDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
        if (locationId !== null) {
            setIsLoading(true);
            axios.post(props.apiHost + `/contingents/getAvailabilityForAll`, {
                "begin": formatDate(firstDay),
                "end": formatDate(lastDay),
                "location_id": locationId,
                "calendar": true,
                "meta": true,
                'only_fe_visible': !props.showAlsoNotFEVisible,
                'network': ((typeof props.network !== 'undefined')?props.network:null),
                'withoutExtraOptions': !props.withExtraOptions
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Accept-Language': props.language
                }
            }).then(res => {
                // Sort availables
                var sortedContingents = res.data.data.locations[0].contingents.sort((a, b) => {
                    if (a.meta === undefined) {
                        return 1;
                    } else if (b.meta === undefined) {
                        return -1;
                    }

                    if (a.meta.name === b.meta.name) {
                        return 0;
                    }

                    if (a.meta.name > b.meta.name) {
                        return 1;
                    }

                    return -1;
                });
                let locationSet = res.data.data.locations[0].meta;
                setLocationsMap(locationSet);
                setAvailables(sortedContingents);
                setIsLoading(false);
            });
        }
    }, [locationId, selectedDate, props]);

    const generateClientHash = (client_id) => {
        return client_id.toString() + sha1(btoa(parseInt(client_id) * 7532 + parseInt(client_id)))

        // SHA1(BASE64({client_id} * 7532 + {client_id}))
    };

    const verifyClientHash = (hash) => {
        let hash_length = hash.length - 40;
        let client_id = parseInt(hash.substr(0,hash_length));
        return (generateClientHash(client_id) === hash);
    };

    const getClientIdFromHash = (hash) => {
        let hash_length= hash.length - 40;
        if(verifyClientHash(hash)){
            return (parseInt(hash.substr(0,hash_length)));
        }
        return false;
    };

   const client_id = getClientIdFromHash(props.hash);
    useEffect(() =>{
        if(locations !== []) {
            let locationSet = [];
            if (client_id !== 7 || (client_id === 7 && typeof props.network !== 'undefined')) {
                setAllLocations(true);
                var firstDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
                var lastDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
                setLocationId(null);
                setIsLoading(true);https://cal.inbooma.net/?hash=74b907031988fdbbba16fc26baaec36eb18c1b332

                axios.post(props.apiHost + `/contingents/getAvailabilityForAll`, {
                    "begin": formatDate(firstDay),
                    "end": formatDate(lastDay),
                    "client_id": client_id,
                    "calendar": true,
                    "meta": true,
                    'only_fe_visible': !props.showAlsoNotFEVisible,
                    'network': (typeof props.network !== 'undefined')?props.network:null,
                    'withoutExtraOptions': !props.withExtraOptions
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Accept-Language': props.language
                    }
                }).then(res => {
                    // Sort availables
                    let locationSet = [];
                    if (typeof res.data.data.client !== 'undefined') {
                        let sortedContingents = [];
                        res.data.data.client.locations.forEach(function (location) {
                            sortedContingents[location.id] = location.contingents.sort((a, b) => {
                                if (a.meta === undefined) {
                                    return 1;
                                } else if (b.meta === undefined) {
                                    return -1;
                                }

                                if (a.meta.name === b.meta.name) {
                                    return 0;
                                }

                                if (a.meta.name > b.meta.name) {
                                    return 1;
                                }

                                return -1;
                            });
                            locationSet[location.id] = location.meta;
                        });
                        setLocationsMap(locationSet);
                        setAvailables(sortedContingents);
                        setIsLoading(false);
                    }
                })
            } else if (client_id === 7 && typeof props.network === 'undefined' && locationId !== null) {
                var firstDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
                var lastDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
                setIsLoading(true);
                axios.post(props.apiHost + `/contingents/getAvailabilityForAll`, {
                    "begin": formatDate(firstDay),
                    "end": formatDate(lastDay),
                    "location_id": locationId,
                    "calendar": true,
                    "meta": true,
                    'only_fe_visible': !props.showAlsoNotFEVisible,
                    'withoutExtraOptions': !props.withExtraOptions
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Accept-Language': props.language
                    }
                }).then(res => {
                    if (typeof res.data.data.locations !== 'undefined') {
                        var sortedContingents = res.data.data.locations[0].contingents.sort((a, b) => {
                            if (a.meta === undefined) {
                                return 1;
                            } else if (b.meta === undefined) {
                                return -1;
                            }

                            if (a.meta.name === b.meta.name) {
                                return 0;
                            }

                            if (a.meta.name > b.meta.name) {
                                return 1;
                            }

                            return -1;
                        });
                        locationSet = res.data.data.locations[0].meta;
                        setLocationsMap(locationSet);
                        setAllLocations(false);
                        setAvailables(sortedContingents);
                        setIsLoading(false);
                    }
                })
            }

        }
    },[selectedDate, client_id, locations, locationId, props]);



    const handleDateChange = (date) => {
        var newDate = new Date(date.setMonth(date.getMonth()));
        setSelectedDate(newDate);
        setChosenDate(true);
        setDaysOfMonthList(Array.from({length: daysInThisMonth()}, (_, i) => i + 1))
    }

    const handleLocationChange = (location) => {
        if (location !== null) {
            setLocationId(location.id);
        } else {
            setLocationId(null)
        }
    }

    function dateComponentPad(value) {
        var format = String(value);

        return format.length < 2 ? '0' + format : format;
    }

    function formatDate(date) {
        var datePart = [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(dateComponentPad);
        var timePart = [date.getHours(), date.getMinutes(), date.getSeconds()].map(dateComponentPad);

        return datePart.join('-') + ' ' + timePart.join(':');
    }

    const previousMonth = () => {
        var newDate = new Date(selectedDate.setMonth(selectedDate.getMonth() - 1));
        setSelectedDate(newDate);
        setDaysOfMonthList(Array.from({length: daysInThisMonth()}, (_, i) => i + 1))
    }

    const nextMonth = () => {
        var newDate = new Date(selectedDate.setMonth(selectedDate.getMonth() + 1));
        setSelectedDate(newDate);
        setDaysOfMonthList(Array.from({length: daysInThisMonth()}, (_, i) => i + 1))
    }

    const daysInThisMonth = () => {
        return new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).getDate();
    }

    const getStripedStyle = (calendarEntry) => {
        const dt = new Date(calendarEntry.date);
        const today = new Date();
        dt.getDay()

        if (dt.getDate() === today.getDate() &&
            dt.getMonth() === today.getMonth() &&
            dt.getFullYear() === today.getFullYear()) {
            return {background: '#EB6A3A', color: '#fafafa'}
        } else if (dt.getDay() === 6 || dt.getDay() === 0) {
            return {background: 'rgb(235 106 58 / 31%)'};
        }

        return {background: '#fafafa'};
    }

    let locationLabel = {
        "de_DE": "Abholstandort (durchsuchbar)",
        "en_GB": "Pickup station (searchable)",
        "fr_FR": "Lieu de retrait"
    };
    let datePickerlabel = {
        "de_DE": "Jahr und Monat",
        "en_GB": "Year and month",
        "fr_FR": "Année et mois"
    };
    let SetStyle = {};
    if(allLocations){
        SetStyle = {
            textAlign:"left"
        };
    }
let locationName='';
    if(availables !== [] && availables !== undefined && allLocations && typeof props.network !== 'undefined') {
        availables.forEach(function(items, index) {

            // get Location Name
            let LocationNames = items.map((contingent) => {
                return contingent.meta.name;
            })
            if (typeof LocationNames[0] !== 'undefined') {
                locationName = LocationNames.join(', ');
            }

        });
    }
    return (
                <Container className={classes.mainContainer} justify="space-around">
                    <Grid container className={classes.optionGrid}>
                        {locations !== [] && locations !== undefined && !allLocations && locationId === null &&
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                id="combo-box-demo"
                                onChange={(event, value) => handleLocationChange(value)}
                                // defaultValue="Kein Standort"
                                options={locations}
                                getOptionLabel={(option) => option.name}
                                style={{width: 400}}
                                renderInput={(params) => <TextField {...params}
                                                                    label={locationLabel[props.language]}
                                                                    variant="outlined"/>}
                            />
                        </Grid>
                        }
                        <Grid item xs={12} sm={6} className={classes.datepickerAndButtons}  style={SetStyle}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language}>
                            <DatePicker
                                locale={de}
                                className={classes.datepicker}
                                minDate={minDate}
                                autoOk
                                variant="inline"
                                openTo="month"
                                views={["year", "month"]}
                                label={datePickerlabel[props.language]}
                                value={selectedDate}
                                onChange={selectedDate => handleDateChange(selectedDate)}
                            />
                            </MuiPickersUtilsProvider>
                            <IconButton
                                onClick={previousMonth}>
                                <ArrowBackIosIcon/>
                            </IconButton>
                            <IconButton
                                onClick={nextMonth}>
                                <ArrowForwardIosIcon/>
                            </IconButton>
                            {isLoading === true &&
                            <CircularProgress/>
                            }
                            {isLoading !== true &&
                            <CircularProgress style={{visibility: "hidden"}}/>
                            }
                        </Grid>
                    </Grid>


                           <span>{((props.language == 'de_DE')?"Gewähltes Datum: ":(props.language == 'en_GB')?"selected date: ":"date choisie: ")} {selectedDate.toLocaleDateString(props.language.replace('_','-'),{month: 'long',year:'numeric'})} </span>
                    <br/><br/>

                            {!allLocations &&
                                <>
                                    <span>{locationsMap.name}</span>
                            <TableContainer className={classes.scrollBar}
                                            style={{maxHeight: "800px", maxWidth: "1900px", overflow: true}}>
                                <Table aria-label="a dense table" stickyHeader={true}>
                                <LocationHead
                                    locationName={locationsMap.name}
                                    daysOfMonthList={daysOfMonthList}
                                    classes={classes}
                                />


                            <TableBody>
                                {availables !== [] &&
                                availables !== undefined && !allLocations &&
                                availables.map((contingent) => {
                                    return (
                                        <ContingentRow
                                            key={locationsMap.name+contingent.id}
                                            contingent={contingent}
                                            network={props.network}
                                            classes={classes}
                                            getStripedStyle={getStripedStyle}
                                            location={locationsMap}
                                            bookingFEHost={props.bookingFEHost}
                                            links={props.links}
                                            showAlsoNotFEVisible={props.showAlsoNotFEVisible}
                                            webFEHost={props.webFEHost}
                                        />
                                        );})}
                                </TableBody>
                                </Table>
                            </TableContainer>
                            </>
                                }
                                {availables !== [] &&
                                availables !== undefined && allLocations && typeof props.network === 'undefined' &&
                                availables.map((items,index) => {
                                    return (
                                        <>
                                            <span>{locationsMap[index].name}</span>
                                        <TableContainer className={classes.scrollBar} key={index}
                                                        style={{maxHeight: "800px", maxWidth: "1900px", overflow: true,marginBottom: "50px"}}>
                                            <Table aria-label="a dense table" stickyHeader={true}>
                                                <LocationHead
                                                    locationName={locationsMap[index].name}
                                                    daysOfMonthList={daysOfMonthList}
                                                    classes={classes}
                                                />
                                                <TableBody>
                                           {items.map((contingent) => {
                                                return (

                                                    <ContingentRow
                                                        key={locationsMap[index].name+contingent.id}
                                                        contingent={contingent}
                                                        network={props.network}
                                                        classes={classes}
                                                        getStripedStyle={getStripedStyle}
                                                        location={locationsMap[index]}
                                                        bookingFEHost={props.bookingFEHost}
                                                        links={props.links}
                                                        showAlsoNotFEVisible={props.showAlsoNotFEVisible}
                                                        webFEHost={props.webFEHost}
                                                    />

                                                );
                                            })
                                            }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        </>
                                    )
                                })}
                    {availables !== [] &&
                    availables !== undefined && allLocations && typeof props.network !== 'undefined' && locationName !== '' &&
                    <>
                        <span>{locationName}</span>
                    <TableContainer className={classes.scrollBar}
                                            style={{maxHeight: "1000px", maxWidth: "1900px", overflow: true,marginBottom: "50px"}}>
                    <Table aria-label="a dense table" stickyHeader={true}>
                        <LocationHead
                            locationName={locationName}
                            daysOfMonthList={daysOfMonthList}
                            classes={classes}
                        />
                        <TableBody>
                        {availables.map((items, index) => {
                            return (
                                <>

                                        {items.map((contingent) => {
                                            return (
                                                <>
                                                <ContingentRow
                                                    key={locationName+contingent.id}
                                                    contingent={contingent}
                                                    name={locationsMap[index].name}
                                                    network={props.network}
                                                    classes={classes}
                                                    getStripedStyle={getStripedStyle}
                                                    location={locationsMap[index]}
                                                    bookingFEHost={props.bookingFEHost}
                                                    links={props.links}
                                                    showAlsoNotFEVisible={props.showAlsoNotFEVisible}
                                                    webFEHost={props.webFEHost}
                                                />
                                               </>
                                            );
                                        })
                                        }

                                </>)
                        })
                        }
                        </TableBody>

                                    </Table>
                                </TableContainer>
                           </>

                    }
                </Container>

    );
}


export default Calendar;
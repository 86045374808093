import React from "react";

const ErrorPage = (props) =>
{
    let error = {
        'de_DE': 'Belegungsplan kann nicht angezeigt werden, fehlender Client Hash!',
        'en_GB': 'Occupancy plan can not be shown, missing client hash!',
        'fr_FR': 'Plan d\'occupation ne peut pas être affiché, hachage manquant!'
    };
    return (
        <>
            <h1 style={{textAlign:"center"}}>{error[props.language]}</h1>
        </>
    )
};

export default ErrorPage;
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import React from "react";

const ContingentRow = (props) => {
    let tags = "";
    let result = false;
    if(typeof props.contingent.meta !== 'undefined') {
        if (typeof props.contingent.meta.tag_list !== 'undefined' && props.contingent.meta.tag_list !== "" && typeof props.network === 'undefined') {

            let tagsTemp = props.contingent.meta.tag_list.split(',');
            for (let i = 0; i < tagsTemp.length; i++) {
                let val = tagsTemp[i].trim();
                if (val === 'S' || val === 'XS' || val === 'M' || val === 'L' || val === 'XL') {
                    tags = " (" + val + ")";
                }
            }
        }
        result = true;
        if (typeof props.network !== 'undefined') {
            if (typeof props.contingent.meta.networks !== 'undefined' && props.contingent.meta.tag_list !== "") {
                let networks = props.contingent.meta.networks.split(',');
                result = false;
                for (let a = 0; a < networks.length; a++) {
                    if (networks[a].trim() === props.network) {
                        result = true;
                    }
                }
            } else {
                result = false;
            }

        }
    }
    const Entry = (props) => {
        let url = props.url;
        let today = new Date(), target = new Date(props.calEntry.date);
        today.setHours(0,0,0,0);
        target.setHours(0,0,0,0,);
        if(props.links === true && today <= target && props.calEntry.available > 0){
            return( <><a target="_blank" rel="noreferrer"
                         href={url}>{props.calEntry.available} / {props.calEntry.max_available}</a></>);
        } else if(props.links !== true || (target < today) || (props.calEntry.available < 1)) {
            return(<><span>{props.calEntry.available} / {props.calEntry.max_available}</span></>);
        }
    };
    const makeid = () => {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 5; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    };
    if (result) {
        return (
            <TableRow key={props.contingent.id} className={props.classes.tableRow} hover>
                {props.contingent.meta &&
                <TableCell
                    key={makeid()}
                    className={props.classes.TableCellSticky}
                    style={{
                        borderRight: '0.1em solid black',
                        padding: '0.4em',
                        minHeight: "25px",
                        minWidth: "85px"
                    }}>
                    {((typeof props.name === 'undefined') ? props.contingent.meta.name + tags : props.name)}
                </TableCell>}
                {props.contingent.meta && props.contingent.calendar.map((calEntry, index) => {
                    let url = "";
                    if(!props.showAlsoNotFEVisible) {
                        let paramObject = {
                            "cart": {
                                "data": {
                                    "begin_location": props.location.id,
                                    "end_location": props.location.id,
                                    "pickup_date": calEntry.date + "T00:01:00+02:00",
                                    "dropoff_date": calEntry.date + "T23:59:00+02:00"
                                }
                            },
                            "cart_items": {
                                "data": [
                                    {
                                        "contingent_id": props.contingent.id,
                                        "amount": 1,
                                        "pricetarff_id": 1,
                                        "cartType": "contingents"
                                    }
                                ]
                            }
                        };
                        let params = btoa(JSON.stringify(paramObject));

                        url = props.bookingFEHost + "&startPage=cart&cartPreset=" + params;
                        if (typeof props.network !== 'undefined') {
                            url += "&network=" + props.network;
                        }
                        url += "#/";
                    } else {
                        url = props.webFEHost + "orders/add";
                    }
                    return (<>
                        <TableCell
                            key={makeid()}
                            align="center"
                            style={{
                                borderRight: '0.05em solid grey',
                                padding: '0.4em', ...props.getStripedStyle(calEntry),
                                minHeight: "25px",
                                minWidth: "85px"
                            }}>
                            <Entry
                                calEntry={calEntry}
                                links={props.links}
                                url={url}
                            />
                        </TableCell>
                    </>);
                })
                }

            </TableRow>

        );
    } else {
        return(<TableRow></TableRow>)
    }
};

export default ContingentRow;